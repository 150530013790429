<template>
  <div class="container">
    <!-- 头部 -->
    <header>
      <div class="top"> {{ $t('trustShip').title }}</div>
    </header>
    <!-- banner -->
    <div class="banner"> <img src="@/assets/img/trustShip_ban.png" /></div>
    <!-- 产品列表 -->
    <div class="pro">
      <div class="item" v-for="(item, index) in pList" :key="`tspro${index}`">
        <div class="left">
          <!-- 产品金额 -->
          <div class="name">{{ item.Name }}</div>
          <div class="price">{{ $t('public').amount }}：{{ item.Price }}（USDT）</div>
        </div>
        <!-- 购买 -->
        <div class="btn" @click="toBuy(item)">{{ $t('public').buy }}</div>
      </div>
    </div>
    <!-- 购买托管 -->
    <van-popup v-model="showBuy" closeable round :style="{ width: '93%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('public').buy }}</div>
        <div class="info">
          <div class="name">{{ proInfo.Name }}</div>
          <!-- 金额 -->
          <div class="price">{{ $t('public').amount }}：{{ Amount }}（USDT）</div>
          <!-- 数量 -->
          <div class="label">{{ $t('trustShip').buynum }}</div>
          <van-field v-model="buyNum" type="digit" :placeholder="$t('trustShip').buynumMsg"></van-field>
          <!-- 选择期限 -->
          <div class="label">{{ $t('trustShip').selectDays }}</div>
          <div class="select">
            <span v-for="(item, index) in InterestRates" :key="`intrate${index}`"
              :class="index == selectRates ? 'active' : ''" @click="selectRates = index">{{ item.Days
              }}{{ $t('trustShip').contractInfo6 }}</span>
          </div>
          <div class="tips" v-if="InterestRates.length > 0"> {{ $t('trustShip').rate }}：{{
            InterestRates[selectRates].Rate }}%</div>
          <div class="contentInfo" v-html="proInfo.Intro"></div>
          <div class="btn" @click="preToPass">{{ $t('public').buy }}</div>
        </div>
      </div>
    </van-popup>
    <!-- 输入密码 -->
    <van-popup v-model="showPassword" closeable round :style="{ width: '93%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('public').Paypassword }}</div>
        <!-- 可用余额 余额不足 -->
        <div class="pswname">{{ $t('trustShip').useBanlace }} <div class="msg" v-if="Amount > userInfo.Balance">{{
          $t('trustShip').noBanlace }}，<span style="color: #C92B2E" @click="$router.push({ name: 'Recharge' })">{{
              $t('trustShip').goRecharge }}</span></div>
        </div>
        <div class="pswname">{{ userInfo.Balance }} USDT</div>
        <div class="password">
          <van-password-input :value="Password" :length="6" :gutter="5" :focused="showKeyboard"
            @focus="showKeyboard = true" />
        </div>
        <div class="pwstips">{{ $t('public').PaypasswordMsg }}</div>
        <div class="pwsbtn" @click="CheckFundPassword">{{ $t('public').confirm }}</div>
      </div>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
    </van-popup>
    <!-- 交易失败 -->
    <van-popup v-model="showFail" closeable round :style="{ width: '65%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="result">
          <img src="@/assets/img/r_fail.png" width="64" />
          <div class="tit">{{ $t('trustShip').tradeFail }}</div>
          <div class="txt">{{ $t('trustShip').Repurchase }}</div>
        </div>
      </div>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
    </van-popup>
    <!-- 交易成功 -->
    <van-popup v-model="showSuccess" closeable round :style="{ width: '65%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="result">
          <img src="@/assets/img/r_success.png" width="64" />
          <!-- 交易成功 -->
          <div class="tit">{{ $t('trustShip').tradeSuccess }}</div>
          <!-- 查看合同 -->
          <div class="txt2"
            @click="$router.push({ name: 'Contract', query: { Name: userInfo.RealName, Amount: Amount, Term: InterestRates[selectRates].Days, Time: new Date() } })">
            {{ $t('trustShip').toConTract }}
          </div>
        </div>
      </div>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
    </van-popup>
    <TabBar :active="'TrustShip'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import productApi from '@/api/product'
import orderApi from '@/api/order'
import TabBar from '@/components/tabBar'
import PublicHeader from '@/components/publicHeader'
import { Token } from '@/utils/mm'

export default {
  components: {
    TabBar,
    PublicHeader
  },
  data() {
    return {
      userInfo: {},
      pList: [],
      proInfo: {},
      InterestRates: [],//产品利率
      selectRates: 0,
      buyNum: null,
      Amount: '',
      Password: '',
      showBuy: false,
      showPassword: false,
      showKeyboard: false,
      showFail: false,
      showSuccess: false,
    }
  },
  watch: {
    buyNum() {
      if (this.buyNum)
        this.Amount = this.proInfo.Price * this.buyNum
    },
    Password() {
      if (this.Password.length == 6)
        this.showKeyboard = false
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    if (this.$route.query.item) {
      this.toBuy(JSON.parse(this.$route.query.item))
    }
    this.getAccountBase()
    this.GetTsProducts()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    // 托管产品列表
    async GetTsProducts() {
      const res = await productApi.GetTsProducts()
      this.pList = res.Data
    },
    // 选择购买
    async toBuy(item) {
      this.proInfo = item
      this.Amount = this.proInfo.Price
      const res = await productApi.GetInterestRates({ Id: item.Id })
      this.InterestRates = res.Data
      this.showBuy = true
    },
    //交易密码预检
    async preToPass() {
      if (this.InterestRates.length < 1) {
        this.$toast(this.$t('trustShip').selectDaysMsg)
        return false
      }
      if (!this.buyNum) {
        this.$toast(this.$t('trustShip').buynumMsg)
        return false
      }
      //是否实名认证
      if (this.userInfo.AuditStatus != 'Success') {
        this.$dialog.alert({
          title: this.$t('public').noAuthisg,
          confirmButtonText: this.$t('public').confirm,
        })
          .then(() => {
            this.$router.push({ name: 'Auth' })
          })
        return false
      }
      //是否设置交易密码
      if (!this.userInfo.BindFundPassword) {
        this.$dialog.alert({
          title: this.$t('public').noPayPasswordMsg,
          confirmButtonText: this.$t('public').confirm,
        })
          .then(() => {
            this.$router.push({ name: 'SetPayPwd' })
          })
        return false
      }
      // 限购校验
      const form = {
        ProductId: this.proInfo.Id,
        TsInterestRateId: this.InterestRates[this.selectRates].Id,
        Num: this.buyNum,
      }
      await orderApi.AddTsOrderLimit(form)
      this.showPassword = true
      this.showKeyboard = true
    },
    //校验密码
    async CheckFundPassword() {
      // 余额校验
      if (this.Amount > this.userInfo.Balance) {
        this.$toast(this.$t('with').BalanceNot)
        return false
      }
      if (this.Password.length < 6) {
        this.$toast(this.$t('public').PaypasswordMsg)
        return
      }
      this.Password = this.Password.slice(0, 6)
      await userApi.CheckFundPassword({ Str: this.Password })
      this.handleSub()
    },
    // 提交
    async handleSub() {
      const form = {
        ProductId: this.proInfo.Id,
        TsInterestRateId: this.InterestRates[this.selectRates].Id,
        Num: this.buyNum,
      }
      await orderApi.AddTsOrder(form)
      this.showPassword = false
      this.showKeyboard = false
      this.showBuy = false
      this.buyNum = ''
      this.Password = ''
      this.showSuccess = true
    },

  }
}
</script>

<style lang="less" scoped>
.red {
  color: #FF5639;
}

.green {
  color: #02B270
}

.container {
  min-height: 100vh;
  padding-bottom: 70px;
  background: #F6F6F6;

  header {
    width: 100%;
    height: 120px;
    background-image: url(../../assets/img/heard_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .top {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: 17px;
      color: #333333;
    }
  }

  .banner {
    margin: -70px 13px 0 13px;
    text-align: center;
  }

  .pro {
    margin: 0 13px;

    .item {
      margin-top: 10px;
      height: 73px;
      padding: 0 10px;
      background: #FFFFFF;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        .name {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }

        .price {
          margin-top: 10px;
          font-size: 14px;
          color: #333333;
        }
      }

      .btn {
        width: 60px;
        height: 29px;
        background: #C92B2E;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #FFFFFF;
      }
    }





  }

  .popInfo {
    .van-cell {
      background: #F2F2F2;
      padding: 10px;
      border-radius: 5px;
      font-size: 15px;
    }

    ::v-deep .van-password-input__security {
      height: 49px;
    }

    ::v-deep .van-password-input__security li {
      background: #E8E8E8;
      border-radius: 5px;
    }

    .title {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #DCDCDC;
    }

    .password {
      margin-top: 80px;
    }

    .pswname {
      margin: 20px 13px 0 13px;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .msg {
        font-weight: 500;
        font-size: 13px;
        color: #333333;

        span {
          color: #C92B2E;
        }

      }

    }

    .pwstips {
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      color: #999999;
    }

    .pwsbtn {
      margin: 60px 90px 150px 90px;
      font-size: 15px;
      color: #FFFFFF;
      height: 40px;
      background: #309C62;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .info {
      padding: 15px 13px 20px 13px;

      .name {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      .price {
        margin-top: 10px;
        font-size: 14px;
        color: #333333;
      }

      .label {
        margin: 20px 0 10px 0;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
      }

      .select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-bottom: 10px;
          width: 95px;
          height: 40px;
          background: rgba(235, 235, 235, 0);
          border-radius: 5px;
          border: 1px solid #CECECE;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: #333333;
        }

        .active {
          color: #fff;
          background: #C92B2E;
        }
      }

      .tips {
        font-size: 14px;
        color: #333333;
        text-transform: none;
      }

      .contentInfo{
        margin-top: 20px;
        line-height: 20px;
      }

      .btn {
        margin: 60px 90px 0 90px;
        font-size: 15px;
        color: #FFFFFF;
        height: 40px;
        background: #309C62;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }

    .result {
      text-align: center;
      padding: 45px 0 25px 0;

      .tit {
        margin-top: 10px;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        text-transform: none;
      }

      .txt {
        margin-top: 25px;
        font-size: 14px;
        color: #C92B2E;
      }

      .txt2 {
        margin-top: 25px;
        font-size: 14px;
        color: #999999;
      }

    }
  }

}

::v-deep .van-popup__close-icon {
  color: #000000;
}
</style>
