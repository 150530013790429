import { fetch, post } from '@/utils/axios'

export default {
  // 新增托管订单限购校验
  AddTsOrderLimit: (data) => {
    return post('/ex/TsOrder/AddTsOrderLimit', data)
  },
  // 新增托管订单
  AddTsOrder: (data) => {
    return post('/ex/TsOrder/AddTsOrder', data)
  },
  // 获取托管订单记录
  GetTsOrderLog: (params) => {
    return fetch('/ex/TsOrder/GetTsOrderLog', params)
  },
  // 获取托管订单详情
  GetTsOrderDetail: (params) => {
    return fetch('/ex/TsOrder/GetTsOrderDetail', params)
  },
  // 中止托管订单
  StopTsOrder: (params) => {
    return fetch('/ex/TsOrder/StopTsOrder', params)
  },
  // 获取托管总额
  GetTsOrderAmount: (params) => {
    return fetch('/ex/TsOrder/GetTsOrderAmount', params)
  },
  // 获取团队数据
  GetTsTeamNum: (params) => {
    return fetch('/ex/TsOrder/GetTsTeamNum', params)
  },
  // 获取团队记录
  GetTsTeamList: (params) => {
    return fetch('/ex/TsOrder/GetTsTeamList', params)
  },
}
