<template>
  <van-tabbar v-model="newActive" active-color="#C92B2E" :border="false">
    <van-tabbar-item v-for="(item, index) in list" :key="index" :to="item.to">
      <div class="item">
        <img :src="newActive !== item.to ? item.icon : item.activeIcon" style="width: 24px" />
        <span :style="{ color: newActive === item.to ? '#C92B2E' : '#BFBFBF' }">{{ item.name }}</span>
      </div>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: String,
      default: 'Home'
    }
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig'])
  },
  watch: {
    lang() {
      this.changeLang()
    }
  },
  data() {
    return {
      newActive:'',
      list: []
    }
  },
  mounted() {
    this.newActive = this.active
    this.changeLang()
  },
  methods: {
    changeLang() {
      this.list = []
      this.list.push({
        name: this.$t('tabBar').home,
        icon: require('../assets/img/home.png'),
        activeIcon: require('../assets/img/home-fill.png'),
        to: 'Home'
      })
      this.list.push({
        name: 'ETF',
        icon: require('../assets/img/etf.png'),
        activeIcon: require('../assets/img/etf-fill.png'),
        to: 'Etf'
      })
      this.list.push({
        name: this.$t('tabBar').chat,
        icon: require('../assets/img/chat.png'),
        activeIcon: require('../assets/img/chat-fill.png'),
        to: 'Chat'
      })
      this.list.push({
        name: this.$t('tabBar').trustship,
        icon: require('../assets/img/trustship.png'),
        activeIcon: require('../assets/img/trustship-fill.png'),
        to: 'TrustShip'
      })
      this.list.push({
        name: this.$t('tabBar').property,
        icon: require('../assets/img/property.png'),
        activeIcon: require('../assets/img/property-fill.png'),
        to: 'Property'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.van-tabbar
  border-top 1px solid #EAEAEA
  background: #ffffff;
  height: 60px;
  >>> .van-tabbar-item--active
    background: none;
  .item
    display flex
    flex-direction column
    justify-content center
    align-items center
    img
      margin-bottom 4px
      width 24px
      height 24px
    span
      text-align: center
      white-space: nowrap
      font-size: 12px
      font-weight: bold
</style>
